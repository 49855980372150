.container-sub-nav {
    position: sticky;
    top: var(--header-h);
    background-color: #F9FAE5;
    z-index: 1;

    .sub-nav {
        ul {
            display: flex;
            list-style-type: none;
            margin-bottom: 0;
            padding-left: 0;

            li {
                padding: 2rem 0;
                margin-bottom: 0;

                &:not(:last-child) {
                    margin-right: 8rem;

                    @include media-breakpoint-down(sm) {
                        margin-right: 25px;
                    }
                }

                a {
                    position: relative;
                    color: var(--primary);
                    font-size: 2.5rem;
                    font-weight: 500;
                    transition: font-weight 205ms ease-in-out;

                    @include media-breakpoint-down(sm) {
                        font-size: 12px;
                    }

                    &:hover {
                        font-weight: bold;
                    }

                    &.active {
                        font-weight: bold;

                        &::before {
                            content: "";
                            background: url("/images/subnav-hover_mobile.svg") no-repeat center / contain;
                            width: 30px;
                            height: 48px;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: -10px;

                            @include media-breakpoint-down(sm) {
                                content: "";
                                background: url("/images/subnav-hover_mobile.svg") no-repeat center / contain;
                                width: 22px;
                                height: 35px;
                            }
                        }
                    }
                }
            }

        }
    }
}

.page-home {
    @include media-breakpoint-down(sm) {
        font-size: 12px;
    }

    .container-hero-banner {
        p {
            font-size: 2.6rem;

            @include media-breakpoint-down(sm) {
                font-size: 12px;
                width: 80%;
                line-height: 1.25;
            }
        }
    }

    ul {
        li {
            font-size: 2.5rem;

            @include media-breakpoint-down(sm) {
                font-size: 12px;

            }
        }
    }

    .expand-all {
        display: block;
        color: var(--primary);
        text-decoration: underline;

        @include media-breakpoint-down(sm) {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .border {
        --bs-border-width: 3px;
        --bs-border-color: var(--primary);
        padding: 5rem 4rem 5rem 6rem;
        color: var(--primary);
        margin-bottom: 9.5rem;
        display: flex;
        align-items: center;
        font-weight: bold;

        @include media-breakpoint-down(sm) {
            padding: 25px 20px;
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 0;
        }
    }

    .accordion-container {
        .accordion-item {
            .accordion-content {
                &#content-1 {
                    strong {
                        display: inline-block;
                        margin-bottom: 8rem;

                        @include media-breakpoint-down(sm) {
                            margin-bottom: 30px;
                        }
                    }
                }

                &#content-2 {
                    .row-tips {
                        row-gap: 8rem;

                        @include media-breakpoint-down(sm) {
                            row-gap: 40px;
                        }
                    }

                    .col-apply-cabtreo {
                        > p {
                            width: 80%;
                            margin: 0 auto 3.5rem;
                        }

                        .border {
                            max-height: 180px;
                            padding-left: 4rem;
                            padding-right: 2rem;
                            @include media-breakpoint-down(xxl) {
                                max-height: 197.25px;
                            }
                            @include media-breakpoint-down(xl) {
                                max-height: 256px;
                            }
                            @include media-breakpoint-down(md) {
                                max-height: unset;
                            }

                            &-fr{
                                max-height: 218.5px;

                                @include media-breakpoint-down(xxl) {
                                    max-height: 231px;
                                }
                                @include media-breakpoint-down(xl) {
                                    max-height: 295px;
                                }
                                @include media-breakpoint-down(md) {
                                    max-height: unset;
                                }
                            }
                        }
                    }

                    .col-wash-hands {
                        margin-bottom: 14rem;

                        @include media-breakpoint-down(sm) {
                            margin-bottom: 6rem;
                        }

                        .border {
                            padding-top: 3rem;
                            padding-bottom: 3rem;
                            margin-bottom: 0;
                        }
                    }

                    .col-cold {
                        .border {
                            padding-top: 3rem;
                            padding-bottom: 3rem;
                            padding-right: 2rem;
                        }
                    }
                }

                &#content-3 {
                    margin-bottom: 9rem;

                    .border {
                        --bs-border-width: 3px;
                        --bs-border-color: var(--primary);
                        width: 85%;
                        font-weight: bold;
                        margin-bottom: 0;
                        text-transform: uppercase;

                        @include media-breakpoint-down(sm) {
                            width: 100%;
                        }

                        p {
                            color: var(--primary);
                        }
                    }

                    .row-contraindication, .row-do-not-use-if {
                        row-gap: 8rem;

                        @include media-breakpoint-down(sm) {
                            row-gap: 10px;
                        }
                    }

                    .row-overdose {
                        .border {
                            --bs-border-width: 1px;
                            --bs-border-color: var(--text-color);

                            p {
                                color: var(--text-color);
                                font-size: 2.5rem;
                                font-weight: normal;
                                text-transform: none;
                                @include media-breakpoint-down(sm) {
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                    .row-interact {
                        p {
                            margin-bottom: 1.5rem;
                        }

                        ul:first-child {
                            margin-bottom: 3rem;
                        }

                        ul > li > ul {
                            padding-left: 10px;
                            list-style-type: "- ";

                            > li {
                                padding-left: 5px;
                            }
                        }

                        .border {
                            margin-top: 6rem;
                            letter-spacing: -10;
                            padding: 2.5rem 5rem;

                            @include media-breakpoint-down(sm) {
                                margin-top: 30px;
                            }

                            p {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .container-skin-irritation {
                        p {
                            margin-bottom: 1rem;
                        }
                    }

                    .table-avoid-side-effects {
                        border: 1px solid var(--primary);

                        th {
                            background-color: var(--primary);
                            color: #fff;
                            padding: 1rem;
                            font-weight: 800;
                            font-size: 2.5rem;
                        }

                        td {
                            padding: 1rem;

                            ul {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .col-side-effects {
                        p {
                            margin-bottom: 8rem;

                            @include media-breakpoint-down(sm) {
                                margin-bottom: 10px;
                            }
                        }

                        .table-side-effects {
                            tr {
                                td {
                                    border-top: 1px solid var(--primary);
                                    border-bottom: 1px solid var(--primary);
                                    font-size: 2.5rem;
                                }

                                > td:first-child {
                                    border-right: 1px solid var(--primary);
                                    padding: 5.5rem 5.5rem 5.5rem 0;
                                    width: 66%;
                                }

                                > td:last-child {
                                    width: 34%;
                                    text-align: center;
                                }

                                &:last-child {
                                    td:last-child {
                                        border-top: none;
                                    }
                                }
                            }
                        }
                    }

                    #when-should-i-not-use-cabtreo {
                        p {
                            @include media-breakpoint-down(sm) {
                                margin-bottom: 10px;
                            }
                        }
                    }

                    .how-to-store-main-instruction {
                        font-weight: bold;
                    }


                    .row-how-do-i-store-it{
                        >.col-12:not(:last-child){
                            padding-bottom: 9rem;

                            @include media-breakpoint-down(sm){
                                padding-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .accordion-faq {
            .accordion-faq-item:first-child {
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}
