footer {
    border-top: 5px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, #F48472, #EDF0B6);
    background-color: #F2F2F0;
    padding: 6rem 0;

    .logo-container {
        margin-bottom: 30px;
    }

    a {
        display: inline-block;
        font-size: 2.6rem;
        text-decoration: underline;
        color: var(--text-color);
    }

    .link-bausch-health {
        margin-bottom: 30px;
    }

    .link-legal-notice {

    }

    p {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-down(sm) {
            font-size: 10px;
        }
        
        sup{
            font-size: .55em;
            display: inline-block;
            transform: translateY(-1px);
        }
    }

    .col-right {
        .logo-container {
            margin-bottom: 0;

            &:not(:last-child) {
                margin-right: 8rem;
            }
        }
    }

    .bh-logo{
        max-width: 44%;

        @include media-breakpoint-down(xxl){
            max-width: 60%;
        }

        a{
            display: block;
        }
    }

    .row-mobile {
        .bh-logo {
            a {
                margin-bottom: 0;
            }
        }

        .link-legal-notice {
            margin-bottom: 20px;
            font-size: 12px;
        }

        .link-bausch-health{
            font-size: 12px;
        }

        .paab-logo{
            max-width: 105px;
        }

        .canada-logo{
            max-width: 80px;
        }

        .bh-logo{
            max-width: 260px;
        }
    }
}
