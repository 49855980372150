// Override Bootstrap variables (optional)
$primary: #164E89;
$secondary: #23B4D3;
$tertiary: #F48472;

// @doc https://getbootstrap.com/docs/4.3/utilities/spacing/
$spacer: 4rem;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
);

// @doc https://getbootstrap.com/docs/4.3/layout/grid/#variables
$grid-columns: 12;

// @doc https://getbootstrap.com/docs/4.3/layout/grid/#gutters
$grid-gutter-width: 60px;

// @doc https://getbootstrap.com/docs/4.3/layout/grid/#grid-tiers
$grid-breakpoints: (
        xs: 0, // phone
        sm: 576px, // phone
        md: 768px, // tablet
        lg: 992px, // desktop
        xl: 1300px, // wide desktop
        xxl: 1700px
);
$container-max-widths: (
        sm: 640px,
        md: 820px,
        lg: 1100px,
        xl: 1120px,
        xxl: 1640px,
);

