.btn, a.btn, .cmpboxbtn {
    --primaryColor: #{$primary};
    --secondaryColor: #{$white};
    padding: 1rem 3rem;
    border-radius: 25px;
    position: relative;
    z-index: 1;
    font-weight: 800;
    font-size: 1.7rem;
    border: 1px solid var(--primaryColor);
    color: var(--primaryColor);
    background-color: rgba(var(--secondaryColor), 0);
    transition: color 0.5s ease, backgorund 0.5s ease, border-color 0.5s ease;

    &:hover {
        color: var(--secondaryColor);
        background-color: var(--primaryColor);
        border-color: var(--primaryColor);
    }

    &-plain{
        background-color: var(--primaryColor);
        color: var(--secondaryColor);

        &:hover {
            color: var(--primaryColor);
            background-color: var(--secondaryColor);
            border-color: var(--primaryColor);
        }
    }

    &.disabled {
        filter: grayscale(1);
        pointer-events: none;
    }

    &.btn-small {
        padding: 1rem 2rem;
    }


    &.dark {
        border: 1px solid $primary;
        background: $dark;
    }
}

@each $color, $value in $theme-colors {
    .btn-#{$color} {
        //@include button-variant($value, $value,
        //    // modify
        //$hover-background: lighten($value, 7.5%),
        //$hover-border: lighten($value, 10%),
        //$active-background: lighten($value, 10%),
        //$active-border: lighten($value, 12.5%));
    }

    .elementor-widget-button {
        &.#{$color} {
            a {
                background: $value;
                border: 1px solid $primary;
                color: #fff;

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

.elementor-button {
    @extend .btn;
}

.hs-button{
    @extend .btn;
    @extend .btn-plain;
}

.elementor-btn-border.elementor-widget-button{
    .elementor-widget-container .elementor-button-wrapper .elementor-button{
        @extend .btn;
    }
}

button.hamburger {
    background-color: transparent;
    border: none;
}
