.page-gate {
    font-size: 2rem;
    
    @include media-breakpoint-down(sm){
        font-size: 12px;
    }

    .container-gate {
        > .row {
            .col-instructions {
                p {
                    width: 80%;
                    line-height: 1.5;
                    font-size: 2.5rem;
                    
                    @include media-breakpoint-down(sm){
                        width: 100%;
                    }
                }
            }

            .col-din {
                label, a, button {
                    display: block;
                }

                label {
                    font-size: 2.5rem;
                    font-weight: 800;
                    color: #58585B;
                    margin-bottom: 2rem;

                    @include media-breakpoint-down(sm){
                        margin-bottom: 1rem;
                        margin-top: 25px;
                    }
                }

                input {
                    padding: 2rem 4rem;
                    width: 100%;
                }

                .error {
                    display: none;
                    margin-top: 15px;
                    color: red;
                }

                a {
                    margin-top: 15px;
                    text-decoration: underline;
                }

                button {
                    position: relative;
                    margin-top: 6rem;
                    font-size: 3.8rem;
                    color: #fff;
                    padding: 1rem 5rem;
                    border-radius: 70px;
                    border: none;
                    transition: all 250ms ease-in-out;
                    background: linear-gradient(135deg, var(--primary),var(--primary), var(--secondary)) border-box;

                    @include media-breakpoint-down(sm){
                        font-size: 14px;
                        text-align: center;
                        margin: 0 auto;
                        margin-top: 20px
                    }
                    //&::before {
                    //    z-index: -1;
                    //    content: "";
                    //    position: absolute;
                    //    inset: 0;
                    //    border-radius: 70px;
                    //    border: 5px solid transparent;
                    //    background: linear-gradient(135deg, var(--primary), var(--secondary)) border-box;
                    //    transition: background 250ms ease-in-out;
                    //}
                    //
                    //&:hover {
                    //    background-color: #fff;
                    //    color: var(--primary);
                    //    &::before{
                    //        mask: linear-gradient(to right, #fff 0%, #fff 100%) padding-box,
                    //        linear-gradient(to right, #fff 0%, #fff 100%) border-box;
                    //        mask-composite: exclude;
                    //    }
                    //}
                }
            }
        }
    }
}

.page-gate + footer{
    a.link-bausch-health{
        display: none;
    }
}