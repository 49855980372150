#cmpbox{
    position: fixed !important;
    top: var(--header-h);
    border-radius: 0 !important;
    .cmpboxinner{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 1640px;

        .cmpboxcontent{
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    .cmpboxbtns{
        margin-left: 20px !important;
        margin-top: 2rem !important;
    }

    .cmptxt_hl{
        display: none;
    }

    .cmpboxbtn{
        min-width: 215px;
        padding: 15px 25px !important;
        text-align: center;
        background-color: var(--primary);
        color: #fff;
        border-radius: 70px;
        font-size: 2rem;
        font-weight: 800;

        span{
            vertical-align: baseline !important;
        }
    }
}