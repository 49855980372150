.error-404{
    @include media-breakpoint-down(md){
        padding: 8rem 0;
    }
    h1{
        font-size: 13rem;
        line-height: 1;
        margin: 5rem 0;
    }

    p{
        font-size: 2rem;
        line-height: 1.5;
    }
}
