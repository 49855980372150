header {
    border-bottom: 5px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, #F48472, #EDF0B6);
    padding: 1rem 0;
    background-color: #fff;
    width: 100%;
    z-index: 1;

    &.sticky {
        position: fixed;
    }

    .logo-container {
        max-width: 200px;

        @include media-breakpoint-down(sm){
            max-width: 140px;
        }
    }

    .language-selector, .disconnect {
        font-size: 2rem;
        color: var(--text-color);
        text-decoration: underline;
        
        @include media-breakpoint-down(sm){
            font-size: 13px;
        }
    }

    .language-selector{
    }

    .disconnect {
        margin-left: 4rem;
        display: none;
    }
}
