/* main: ../style.scss*/
html {
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}

a {
    text-decoration: none;
    transition: color $transition;
}

img {
    max-width: 100%;
    height: auto;
}

.box-shadow {
    box-shadow: $box-shadow;
}

section.container{
    margin-top: 5rem;
    margin-bottom: 5.5rem;

    @include media-breakpoint-down(sm){
        margin-bottom: 30px;
        margin-top: 30px;
    }
}

header.sticky ~ .content{
    padding-top: var(--header-h);
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)){
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
        --bs-gutter-x: 50px;
    }
    .row{
        --bs-gutter-x: 50px;
    }
}
