/* main: ../style.scss*/

$max-width: 1400px !default;
$path--rel: "../images";

// Transition
$transition: 250ms ease;

// Box shadow
$box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

// images directory
$images-path: "/wp-content/themes/penega-child/dist/images/";
$dist-path: "/wp-content/themes/penega-child/dist/";

html{
    --primary: #164E89;
    --secondary: #23B4D3;
    --tertiary: #F48472;
    --text-color: #58585B;
}
