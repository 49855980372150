html {
    font-size: 10px;

    @include media-breakpoint-only(xl) {
        font-size: 9px;
    }
    @include media-breakpoint-down(lg) {
        font-size: 8px;
    }
}

body {
    font-family: "Poppins", Arial, sans-serif;
    --bs-body-font-family: "Poppins", Arial, sans-serif;
    font-size: 1.6rem;
    line-height: 1.25;
    color: var(--text-color);
    
    @include media-breakpoint-down(sm){
        font-size: 12px;
    }
}

h1, .title-1 {
    font-size: 6rem;
    font-weight: 800;
    line-height: 1.25;
    
    @include media-breakpoint-down(sm){
        font-size: 3.6rem;
    }

    span{
        font-size: 7.6rem;

        @include media-breakpoint-down(sm){
            font-size: 27px;
        }
    }
}

h2, .title-2 {
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.25;
    
    @include media-breakpoint-down(sm){
        font-size: 16px;
    }
}

h3, .title-3 {
    font-size: 3rem;
    font-weight: 800;
    line-height: 1.25;

    @include media-breakpoint-down(sm){
        font-size: 12px;
    }
}

h4, .title-4 {
    font-size: 2.8rem;
    line-height: 1.25;

    @include media-breakpoint-down(sm){
        font-size: 16px;
        margin-bottom: 1rem;
    }
}

h5, .title-5 {
    font-size: 2rem;
    line-height: 1.25;
}

h1, h2, h3, h4, h5, h6 {

    @include media-breakpoint-down(lg) {
        br {
            content: '';
            margin-left: 0.25em;
        }
    }
}

strong{
    font-weight: bold;
}

.font-bolder{
    font-weight: bolder;
}

ul, ol {
    padding-left: 24px;
    line-height: 1.25;

    @include media-breakpoint-down(sm){
        padding-left: 16px;
    }
    li {
        padding-left: 0;
        line-height: 1.25;

        &:not(:last-child){
            padding-bottom: 1rem;
        }
    }
}
