.din-modal {
    position: fixed;
    display: block;
    opacity: 0;
    top: 0;
    right: -100%;
    width: 50%;
    background-color: #fff;
    z-index: 1001;
    transition: opacity 350ms ease-in-out,
    right 250ms ease-in-out;

    @include media-breakpoint-down(lg){
        width: 66%;
        height: 100vh;

        .container{
            height: 100%;
        }
    }

    @include media-breakpoint-down(sm){
        width: 80%;

        h3{
            font-size: 16px;
        }
    }

    h3{
        color: var(--primary);
    }

    &.active{
        opacity: 1;
        right: 0;
    }

    .close {
        cursor: pointer;
        position: relative;
        color: var(--primary);
        width: 50px;
        aspect-ratio: 1;
        background-color: #fff;
        border-radius: 50%;
        left: -70px;
        top: 50%;
        transform: translateY(-50%);

        &::after {
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            justify-content: center;
            content: "\D7";
            font-size: 40px;
            color: var(--primary);
            line-height: 50px;
            text-align: center;
        }
    }

    > .container > .row > .col-12 {
        padding: 12rem;

        @include media-breakpoint-down(sm){
            padding: 12px 30px;
        }
    }
}

.overlay{
    display: none; /* Caché par défaut */
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Opacité réduite */
    z-index: 1000; /* Plus bas que la modale mais plus haut que le reste */
}