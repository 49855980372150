.accordion-container {
    > .accordion-item {
        margin-bottom: 1.5rem;

        > .accordion-header {
            position: relative;
            cursor: pointer;
            padding: 5rem 0;
            
            @include media-breakpoint-down(sm){
                padding: 10px 0;
            }

            &::after {
                position: absolute;
                top: 0;
                right: 0;
                content: "";
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                height: 100%;
                width: 23%;
            }

            .container {
                position: relative;

                h2 {
                    margin-bottom: 0;
                    margin-left: 10rem;
                    color: var(--primary);

                    @include media-breakpoint-down(sm){
                        margin-left: 20px;
                    }
                }

                &::before {
                    content: "";
                    background: url("/images/arrow.svg") no-repeat center / cover;
                    position: absolute;
                    left: calc(var(--bs-gutter-x) * .5);
                    top: 50%;
                    transform: translateY(-35%);
                    transition: transform 250ms ease-in-out;
                    width: 30px;
                    height: 18px;

                    @include media-breakpoint-down(sm){
                        width: 12px;
                        height: 8px;
                    }
                }
            }


            &.opened {
                .container::before {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }

        &:nth-child(1) {
            .accordion-header::after {
                background-image: url("/images/ribbon_about_cabtreo.svg")
            }
        }

        &:nth-child(2) {
            .accordion-header::after {
                background-image: url("/images/ribbon_applying_cabtreo.svg")
            }
        }

        &:nth-child(3) {
            .accordion-header::after {
                background-image: url("/images/ribbon_faqs.svg")
            }
        }

        > .accordion-content {
            display: none;
            margin-top: 9rem;

            @include media-breakpoint-down(sm){
                margin-top: 15px;
            }

            p, strong {
                font-size: 2.5rem;
                line-height: 1.5;

                @include media-breakpoint-down(sm){
                    font-size: 12px;
                }
            }

            > h3 {
                margin-bottom: 8rem;
                @include media-breakpoint-down(sm){
                    margin-bottom: 25px;
                }
            }

            p:not(:last-child), strong {
                margin-bottom: 3.5rem;
            }

            .img-container {
                margin-bottom: 1rem;

                @include media-breakpoint-down(sm){
                    max-width: 115px;
                    margin-bottom: 10px;
                }
            }

            h4 {
                color: var(--primary);
                font-weight: bold;
            }
        }
    }
}

.accordion-faq {
    .accordion-faq-item {
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }

        .accordion-faq-header {
            padding: 4rem 0;
            cursor: pointer;
            background-color: #E5ECF5;

            @include media-breakpoint-down(sm){
                padding: 10px 0;
            }

            .container {
                position: relative;

                h3 {
                    color: var(--primary);
                    margin-left: 10rem;
                    margin-bottom: 0;

                    @include media-breakpoint-down(sm){
                        margin-left: 5rem;
                    }
                }

                .accordion-faq-icon {
                    cursor: pointer;
                    position: absolute;
                    width: 70px;
                    height: 76px;
                    background: url("/images/hexagon.svg") no-repeat center / contain;
                    left: calc(var(--bs-gutter-x) * .5);
                    top: 50%;
                    transform: translateY(-50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include media-breakpoint-down(sm){
                        width: 30px;
                        height: 30px;
                    }

                    &::after {
                        width: 25px;
                        height: 25px;
                        transform-origin: center;
                        content: "";
                        background: url("/images/arrow_yellow.svg") no-repeat center / contain;
                        transition: transform 250ms ease-in-out;

                        @include media-breakpoint-down(sm){
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }

            &.opened {
                .accordion-faq-icon::after {
                    transform: rotateZ(180deg);
                }
            }
        }

        .accordion-faq-content {
            display: none;
            margin: 5.5rem 0;

            @include media-breakpoint-down(sm){
                margin: 30px 0;
            }
        }
    }
}
