.container-hero-banner{
    background: url("/images/hero_banner.jpg") no-repeat center / cover;
    height: 80vh;
    color: #fff;

    @include media-breakpoint-down(xl){
        background: url('/images/hero_banner.jpg') no-repeat 66% 50% / cover;
    }

    @include media-breakpoint-down(lg){
        height: 70vh;
    }

    @include media-breakpoint-down(md){
        background: url('/images/hero_banner.jpg') no-repeat 75% 50% / cover;
        height: 70vh;
    }

    @include media-breakpoint-down(sm){
        background: url("/images/hero_banner_mobile.jpg") no-repeat 100% 50% / cover;
        height: 45vh;
    }

    &-gate{
        background: url("/images/hero_banner_gate.jpg") no-repeat center / cover;
        height: 70vh;

        @include media-breakpoint-down(xl){
            background: url('/images/hero_banner_gate.jpg') no-repeat 66% 50% / cover;
            height: 90vh;
        }

        @include media-breakpoint-down(lg){
            height: 50vh;
        }

        @include media-breakpoint-down(md){
            background: url('/images/hero_banner_gate.jpg') no-repeat 75% 50% / cover;
            height: 70vh;
        }

        @include media-breakpoint-down(sm){
            background: url("/images/hero_banner_gate_mobile.jpg") no-repeat 100% 50% / cover;
            height: 45vh;
        }
    }

    h1{
        margin-top: 10rem;
        margin-bottom: 5rem;

        @include media-breakpoint-down(sm){
            margin-top: 30px;
            margin-bottom: 2rem;
        }
    }

    h3{
        @include media-breakpoint-down(sm){
            width: 75%;
        }
    }
}