/* main: ../style.scss*/
.clearfix::after {
    clear: both;
    content: '';
    display: table;
}

.hide-text {
    overflow: hidden;
    padding: 0;
    /* 1 */
    text-indent: 101%;
    white-space: nowrap;
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
